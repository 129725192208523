"use client";

import styles from "./FAQ.module.css";
import DropdownArrow from "../../(svgIcons)/dropdownArrow.svg";
import { useState } from "react";

const FAQQuestion = ({ faqQuestion, index }) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen((prevValue) => !prevValue);

  return (
    <div
      className={
        open ? styles.faqQuestionContainerOpen : styles.faqQuestionContainer
      }
    >
      <div className={styles.faqQuestion} onClick={toggleOpen}>
        <h4>
          {index + 1}. {faqQuestion.question}
        </h4>

        <div
          className={open ? styles.dropdownArrowRotate : styles.dropdownArrow}
        >
          <DropdownArrow />
        </div>
      </div>

      <div className={open ? styles.faqAnswerOpen : styles.faqAnswer}>
        <div
          className={`body-text ${styles.faqAnswerText}`}
          dangerouslySetInnerHTML={{ __html: faqQuestion.answer }}
        />
      </div>
    </div>
  );
};

export default FAQQuestion;
